.rotate-180 {
    transform: rotate(180deg);
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pr-5 {
    padding-right: 3rem;
}

.point-event-none {
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.text-boston-blue {
    color: var(--color-boston-blue);
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}